/*
 * @Author: Robin
 * @Date: 2021-03-29 22:16:29
 * @LastEditors: Robin
 * @LastEditTime: 2021-03-29 22:24:38
 * @Description: 主入口文件
 */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false;

Vue.use(ElementUI);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
